<template>
    <div class="w-full relative flex h-screen" style="background-image: url('data:image/svg+xml,%3Csvg width=\'20\' height=\'20\' viewBox=\'0 0 20 20\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M1 1h2v2H1V1zm4 0h2v2H5V1zm4 0h2v2H9V1zm4 0h2v2h-2V1zm4 0h2v2h-2V1zm-16 4h2v2H1V5zm4 0h2v2H5V5zm4 0h2v2H9V5zm4 0h2v2h-2V5zm4 0h2v2h-2V5zm-16 4h2v2H1V9zm4 0h2v2H5V9zm4 0h2v2H9V9zm4 0h2v2h-2V9zm4 0h2v2h-2V9zm-16 4h2v2H1v-2zm4 0h2v2H5v-2zm4 0h2v2H9v-2zm4 0h2v2h-2v-2zm4 0h2v2h-2v-2z\' fill=\'%239C92AC\' fill-opacity=\'0.05\' fill-rule=\'evenodd\'/%3E%3C/svg%3E'); box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.3), 0 0 20px 0px rgba(0, 200, 200, 0.2);">
      <div class="absolute w-full h-full flex items-center justify-center p-8 overflow-y-auto">
        
        <div>
          <!-- Header Section -->
          <div>
            <!-- Header Section -->
            <div class="text-center mb-6" v-motion-slide-top :delay="100">
              <img class="h-24 w-24 rounded-full object-cover mx-auto mb-4 shadow-lg transition-transform duration-300 hover:scale-110" :src="mainStore.user?.profileImage" alt="User profile" />
              <h2 class="text-4xl font-bold text-gray-800 mb-2" v-motion-slide-visible :delay="200">Welcome, {{ mainStore.user?.name }}</h2>
              <p class="text-lg text-gray-600 mt-2" v-motion-slide-visible :delay="300">Please select an organization to manage</p>
            </div>

            <!-- Organizations Grid -->
            <div class="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
              <div v-for="(organization, index) in mainStore.organizations" 
                 :key="organization.id" 
                 @click="selectOrganization(organization)" 
                 class="cursor-pointer bg-white rounded-xl border-2 border-gray-200 shadow-sm hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 relative"
                 v-motion-slide-visible
                 :delay="400 + (index * 100)">
                <!-- Loader Overlay -->
                <div v-if="isLoadingInstructor && selectedOrgId === organization.companyID" class="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10 rounded-xl">
                  <font-awesome-icon icon="fa-duotone fa-solid fa-spinner-third" spin class="w-10 h-10 text-teal-500" />
                </div>
              
                <div class="p-6">
                  <div class="flex items-center justify-between mb-4">
                    <div class="flex items-center">
                      <img :src="organization.profileImage ?? 'https://host.refreps.com/images/org_default.webp'" alt="Organization logo" class="w-16 h-16 object-cover rounded-full mr-4 border-2 border-gray-200" />
                      <div>
                        <h3 class="text-xl font-semibold text-gray-800">{{ organization.name }}</h3>
                        <p class="text-sm text-gray-600">Instructor</p>
                      </div>
                    </div>
                    <!-- Notifications Icon -->
                    <div class="relative" v-if="organization.notificationCount > 0">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                      </svg>
                      <span class="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold rounded-full h-5 w-auto px-1 flex items-center justify-center">
                        {{ organization.notificationCount }}
                      </span>
                    </div>
                  </div>
                  <div class="grid grid-cols-2 gap-4 text-sm text-gray-700">
                    <div v-if="organization.numberOfUsers !== undefined" class="flex items-center bg-gray-50 p-2 rounded">
                      <font-awesome-icon icon="fa-regular fa-solid fa-users" class="w-5 h-5 mr-2 text-gray-400" />
                      <div>
                        <div class="font-semibold">{{ organization.numberOfUsers }}</div>
                        <div class="text-xs text-gray-500">Total Users</div>
                      </div>
                    </div>
                    <div v-if="organization.activeUsers !== undefined" class="flex items-center bg-gray-50 p-2 rounded">
                      <font-awesome-icon icon="fa-regular fa-person-walking" class="w-5 h-5 mr-2 text-gray-400" />
                      <div>
                        <div class="font-semibold">{{ organization.activeUsers }}</div>
                        <div class="text-xs text-gray-500">Active Users</div>
                      </div>
                    </div>
                    <div v-if="organization.prepCourses !== undefined" class="flex items-center bg-gray-50 p-2 rounded">
                      <font-awesome-icon icon="fa-regular fa-solid fa-books" class="w-5 h-5 mr-2 text-gray-400" />
                      <div>
                        <div class="font-semibold">{{ organization.prepCourses.length }}</div>
                        <div class="text-xs text-gray-500">Courses</div>
                      </div>
                    </div>
                    <div v-if="organization.seats !== undefined" class="flex items-center bg-gray-50 p-2 rounded">
                      <font-awesome-icon icon="fa-regular fa-solid fa-loveseat" class="w-5 h-5 mr-2 text-gray-400" />
                      <div>
                        <div class="font-semibold">{{ organization.seats }}</div>
                        <div class="text-xs text-gray-500">Seats</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import useMainStore from '@/stores/main'
import axios from 'axios'

export default {
  computed: {
    ...mapStores(useMainStore)
  },
  data() {
    return {
      isLoadingInstructor: false,
      selectedOrgId: null,
      organizations: []
    }
  },
  methods: {
    selectOrganization(organization) {
      this.isLoadingInstructor = true
      this.selectedOrgId = organization.companyID
      this.mainStore.organization = organization
      // Simulate API call delay
      setTimeout(() => {
        this.$router.push({
          name: 'organization',
          params: {
            id: organization.companyID
          }
        })
      }, 1000)
    },
  },

}
</script>

<style scoped>
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>