// File: src/utils/formatUtils.js

function maskNumber(number) {
  if (!number) return '';
  const digitsOnly = number.replace(/\D/g, '');
  if (digitsOnly.length === 10) {
    return digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, '***-***-$3');
  } else if (digitsOnly.length === 11) {
    return digitsOnly.replace(/(\d)(\d{3})(\d{3})(\d{4})/, '*-***-***-$4');
  }
  return '*'.repeat(Math.max(0, digitsOnly.length - 4)) + digitsOnly.slice(-4);
}

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
}

const maskEmail = (email) => {
  if (!email) return '';
  const [name, domain] = email.split('@');
  return `${name[0]}${'*'.repeat(name.length - 2)}${name[name.length-1]}@${domain}`;
};

// Change this export
export { maskNumber, formatTime, maskEmail};