<template>
    <div id="videoModal2" class="animate__animated animate__fadeIn w-[100vw] h-[100vh] fixed flex justify-center inset-0 bg-white bg-opacity-90" style="z-index: 9999; --animate-duration: 0.5s">
    
        <div class="animate__animated animate__fadeInUp" style="{ --animate-duration: 0.1s }">
            <div class="flex items-center justify-center h-[100vh]">
                <div class="bg-white rounded-md shadow overflow-y-auto w-full max-w-6xl">
                    <div class="bg-blue-900  rounded-tl-md rounded-tr-md px-4 md:px-8 md:py-4 py-7 flex items-center justify-between">
                        <p class="text-base font-semibold text-white" id="videoTitle">{{ mainStore.videoTitle }}</p>
                        <button role="button" aria-label="close label" @click="mainStore.showRuleVideo = false" class="focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 focus:outline-none">
                            <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/add_user-svg1.svg" alt="icon" />
    
                        </button>
                    </div>
                    <div id="container" class="mx-auto">
                        <vue-plyr ref="plyr">
                            <video :src="mainStore.ruleVideoURL" style="z-index: 99999;"></video>
                        </vue-plyr>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import useMainStore from '@/stores/main'
    import {
        mapStores
    } from 'pinia'
    
    export default {
        data() {
            return {
                player: null,
            }
        },
        computed: {
            ...mapStores(useMainStore),
        },
        beforeDestroy() {
    
            this.mainStore.videoTitle = ''
            this.mainStore.videoPreviewLink = ''
            this.mainStore.showPreview = false
    
            if (this.player) {
                this.player.dispose();
            }
        },
    }
    </script>
    
    <style>
    
    </style>
    