<template>
    <div class="mt-6">
      <h3 class="text-lg font-semibold text-gray-700 mb-4">Select an account to modify:</h3>
      <div class="grid grid-cols-1 sm:grid-cols-1 gap-4">
        <div
          v-for="account in accounts"
          :key="account.id"
          class="flex items-center bg-gray-100 p-4 rounded-lg shadow-sm cursor-pointer hover:shadow-md transition-all duration-300"
          @click="selectAccount(account)"
        >
          <img
            :src="account.profileImage"
            :alt="`${account.name}'s profile`"
            class="w-12 h-12 rounded-full object-cover border-2 border-gray-300 mr-4"
          />
          <div class="flex-grow">
            <h2 class="text-base font-semibold text-gray-800">{{ account.name }}</h2>
            <p class="text-sm text-gray-600">{{ account.email }}</p>
            <p class="text-xs text-gray-500">Last logged in: {{ formatTimestamp(account.lastLoggedInTimestamp) }}</p>
            <p v-if="account.loginIP" class="text-xs text-gray-500">Last logging from IP: {{ account.loginIP }}</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import { formatTimestamp } from '@/utils/dateUtils.js';
  
  export default defineComponent({
  name: 'MultipleAccountsView',
  props: {
    accounts: {
      type: Array,
      required: true,
      validator: (value) => value.every(account => 
        typeof account === 'object' && 
        'id' in account && 
        'name' in account && 
        'email' in account && 
        ('profileImage' in account || true) && 
        ('lastLoggedInTimestamp' in account || true) &&
        ('loginIP' in account || true)
      )
    }
  },
  emits: ['select-account'],
  setup(props, { emit }) {
    const selectAccount = (account) => {
      emit('select-account', account);
    };

    return {
      selectAccount,
      formatTimestamp
    };
  }
});
</script>