<template>
  <div class="single-account-view p-8 shadow-xl">
    <div class="mb-6 text-center">
      
      <div v-if="userData" class="mt-4 p-4 mx-auto text-center flex items-center justify-center">
        <img :src="userData?.profileImage" :alt="`${userData?.name}'s profile`" class="w-24 h-24 rounded-full object-cover mr-3" />
      </div>

      <h2 class="text-3xl font-bold mb-4">Enter Verification Code</h2>
      <transition name="slide-fade-fast" mode="out-in">
        <div v-if="isEmailOption && didSendSMS || !isEmailOption" class="text-gray-700 rounded-lg" role="alert">
          <p class="text-sm mb-2">Verification code sent to <span class="font-bold">{{ maskNumber(userData?.phone) }}</span>.</p>
          <p class="text-sm text-teal-600 mt-4 py-3 flex justify-center items-center">
            <font-awesome-icon icon="fa-solid fa-clock" class="mr-1 py-0" /> Expires in: {{ formatTime(remainingTime) }}
          </p>
        </div>
        <p v-else class="text-sm text-gray-600 mb-6">
          We've sent a 6-digit code to your <br> {{ userData.email }}.
          <br>
          <span class="text-sm mb-2 text-teal-600 mt-4 flex justify-center items-center">
            <font-awesome-icon icon="fa-solid fa-clock" class="mr-1 py-2" /> Expires in: {{ formatTime(remainingTime) }}
          </span>
        </p>
      </transition>
    </div>

    <div class="flex justify-center space-x-4 mb-8">
      <input
        v-for="(digit, index) in code"
        :key="index"
        :ref="el => inputRefs[index] = el"
        type="text"
        inputmode="numeric"
        pattern="[0-9]*"
        maxlength="1"
        v-model="code[index]"
        @input="handleInput(index, $event)"
        @keydown="handleKeyDown(index, $event)"
        @paste="handlePaste($event)"
        class="w-16 h-16 text-center text-4xl font-bold border-3 border-gray-300 rounded-xl focus:border-teal-600 focus:ring-4 focus:ring-teal-300 focus:ring-opacity-50 transition duration-300 shadow-md hover:shadow-lg transform hover:scale-105"
      />
    </div>

    <div v-if="verificationStatus === 'error'" class="mb-4 text-sm text-center text-red-800">
      {{ verificationError }}
    </div>

    <button @click="verifyCode" class="w-full bg-teal-600 text-white py-4 px-6 rounded-lg hover:bg-teal-700 transition duration-200 font-semibold text-xl shadow-md">
      <font-awesome-icon icon="fa-duotone fa-solid fa-badge-check" class="mr-2" /> {{ isVerifying ? 'Verifying...' : 'Verify Code' }}
    </button>

    <div v-if="isEmailOption && !didSendSMS" class="mt-6 text-center">
      <p class="text-sm text-gray-600 mb-2">Didn't receive the code?</p>
      <button @click="sendVerificationCode" class="text-teal-600 hover:text-teal-700 transition duration-200 text-lg font-medium flex items-center justify-center mx-auto">
        <font-awesome-icon icon="fa-solid fa-envelope" class="mr-2" />
        Resend Code to {{ maskNumber(userData?.phone) }}
      </button>
    </div>

    <button @click="startOver" class="mt-8 w-full text-gray-600 transition duration-200 font-medium text-base">
      <font-awesome-icon icon="fa-solid fa-rotate-right" class="mr-2" /> Start Over
    </button>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, watch, onMounted } from 'vue';
import { useVerificationCode } from '@/composables/useVerificationCode';
import { maskNumber, maskEmail, formatTime } from '@/utils/formatUtils';

export default defineComponent({
  name: 'SingleAccountView',
  props: {
    userData: {
      type: Object,
      required: true
    },
    codeSent: {
      type: Boolean,
      default: false
    },
    remainingTime: {
      type: Number,
      required: true
    },
    recoveryMethod: {
      type: String,
      required: true
    },
    verificationStatus: {
      type: String,
      default: ''
    },
    verificationError: {
      type: String,
      default: ''
    },
    isVerifying: {
      type: Boolean,
      default: false
    },
    isEmailOption: {
      type: Boolean,
      default: false
    }
  },
  emits: ['verify-code', 'send-verification-code', 'start-over', 'start-timer'],
  setup(props, { emit }) {
    const { code, inputRefs, handleInput, handleKeyDown, handlePaste } = useVerificationCode();

    const { isEmailOption } = toRefs(props);
    const didSendSMS = ref(false);

    const verifyCode = () => {
      const enteredCode = code.join('');
      if (enteredCode.length === 6 && /^\d+$/.test(enteredCode)) {
        emit('verify-code', { email: props.userData.email, code: enteredCode });
      }
    };

    const sendVerificationCode = () => {
      didSendSMS.value = true;
      emit('send-verification-code');
    };

    const startOver = () => {
      emit('start-over');
    };

    watch(() => props.codeSent, (newValue) => {
      if (newValue) {
        inputRefs.value[0]?.focus();
      }
    });

    onMounted(() => {
      emit('start-timer');
      console.log(isEmailOption.value);
      inputRefs.value[0]?.focus();
    });

    return {
      code,
      inputRefs,
      handleInput,
      handleKeyDown,
      handlePaste,
      verifyCode,
      sendVerificationCode,
      startOver,
      maskNumber,
      formatTime,
      didSendSMS
    };
  }
});
</script>

<style scoped>
.slide-fade-fast-enter-active,
.slide-fade-fast-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-fast-enter-from,
.slide-fade-fast-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>