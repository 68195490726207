// /src/composables/useVerificationCode.js

import { ref, reactive } from 'vue';

export function useVerificationCode() {
  const code = reactive(Array(6).fill(''));
  const inputRefs = ref([]);

  const handleInput = (index, event) => {
    const value = event.target.value.slice(-1);
    if (isNaN(value)) return;
    code[index] = value;

    if (value !== '' && index < 5) {
      inputRefs.value[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && code[index] === '') {
      code[index - 1] = '';
      inputRefs.value[index - 1]?.focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const digits = pastedText.replace(/\D/g, '').slice(0, 6);
    
    digits.split('').forEach((digit, index) => {
      if (index < 6) {
        code[index] = digit;
      }
    });

    const focusIndex = code.findIndex(digit => digit === '') ?? 5;
    inputRefs.value[focusIndex]?.focus();
  };

  return {
    code,
    inputRefs,
    handleInput,
    handleKeyDown,
    handlePaste
  };
}
