// /src/composables/usePasswordValidation.js

import { computed, ref } from 'vue';

export function usePasswordValidation(password) {
  const confirmPassword = ref('');

  const passwordRequirements = computed(() => [
    { text: 'At least 8 characters long', met: password.value.length >= 8 },
    { text: 'Contains at least one uppercase letter', met: /[A-Z]/.test(password.value) },
    { text: 'Contains at least one lowercase letter', met: /[a-z]/.test(password.value) },
    { text: 'Contains at least one number', met: /\d/.test(password.value) },
    { text: 'Contains at least one special character', met: /[!@#$%^&*(),.?":{}|<>]/.test(password.value) },
  ]);

  const isPasswordValid = computed(() => passwordRequirements.value.every(req => req.met));

  const isConfirmationValid = computed(() => 
    password.value === confirmPassword.value && confirmPassword.value !== ''
  );

  return {
    passwordRequirements,
    isPasswordValid,
    isConfirmationValid,
    confirmPassword
  };
}