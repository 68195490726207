// @/composables/useTimer.js

import { ref, onUnmounted } from 'vue';

export function useTimer(initialTime) {
  const remainingTime = ref(initialTime);
  let timer;

  const startTimer = () => {
    clearInterval(timer);
    remainingTime.value = initialTime;
    timer = setInterval(() => {
      if (remainingTime.value > 0) {
        remainingTime.value--;
      } else {
        clearInterval(timer);
      }
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timer);
  };

  onUnmounted(() => {
    clearInterval(timer);
  });

  return {
    remainingTime,
    startTimer,
    stopTimer
  };
}