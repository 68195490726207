<!-- /src/components/PasswordRequirements.vue -->
<template>
  <ul class="space-y-2 text-sm text-left text-gray-600">
    <li v-for="requirement in requirements" :key="requirement.text" :class="requirement.met ? 'text-teal-500' : 'text-gray-400'">
      <font-awesome-icon :icon="requirement.met ? 'fa-solid fa-circle-check' : 'fa-solid fa-xmark'" class="mr-2" />      {{ requirement.text }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    requirements: {
      type: Array,
      required: true
    }
  }
}
</script>
