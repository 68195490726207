<!-- /src/components/PasswordInput.vue -->
<template>
  <div class="relative p-2.5 border border-gray-200 transition-all duration-300 ease-in-out rounded-lg">
    <div class="flex items-center">
      <transition name="slide-fade">
        <font-awesome-icon v-if="isValid" icon="fa-solid fa-circle-check" class="text-teal-500 absolute left-2.5 top-1/2 transform -translate-y-1/2 transition-all duration-300" />
      </transition>
      <label :for="id" class="block text-xs font-medium text-gray-500 transition-all duration-300 ease-in-out pl-6" 
             :class="{ 'text-teal-600 text-[10px]': isFocused }">{{ label }}</label>
    </div>
    <input
      :id="id"
      :type="showPassword ? 'text' : 'password'"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      @focus="isFocused = true"
      @blur="isFocused = false"
      class="block w-full border-0 p-0 text-gray-700 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 bg-transparent font-medium px-6"
      required
    />
    <button
      type="button"
      @click="$emit('toggle-visibility')"
      class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
    >
      <font-awesome-icon :icon="['fas', showPassword ? 'eye-slash' : 'eye']" />
    </button>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    modelValue: String,
    label: String,
    id: String,
    showPassword: Boolean,
    isValid: Boolean,
  },
  emits: ['update:modelValue', 'toggle-visibility'],
  setup() {
    const isFocused = ref(false);
    return {
      isFocused
    };
  }
}
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(5px);
  opacity: 0;
}
</style>