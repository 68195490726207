<template>
<div class="verification-code-input">
    <transition name="fast-slide-fade" mode="out-in">
      <single-account-view
        v-if="codeSent && verificationStatus !== 'success'"
        :userData="userData"
        :recoveryMethod="recoveryMethod"
        :codeSent="codeSent"
        :remainingTime="remainingTime"
        :verificationStatus="verificationStatus"
        :verificationError="verificationError"
        :isVerifying="isVerifying"
        :isEmailOption="recoveryMethod === 'email'"
        @verify-code="verifyCode"
        @send-verification-code="sendVerificationCode"
        @start-over="startOver"
      />
        <password-reset-view v-else-if="verificationStatus === 'success'" @reset-password="handleResetPassword" :reset-error="resetError" />
    </transition>
</div>
</template>

<script>
import {
    defineComponent,
    ref,
    watch
} from 'vue';
import SingleAccountView from '@/components/Login/ForgotPassword/SingleAccountView.vue';
import PasswordResetView from '@/components/Login/ForgotPassword/PasswordResetView.vue';
import {
    useTimer
} from '@/composables/useTimer';

export default defineComponent({
    name: 'VerificationCodeInput',
    components: {
        SingleAccountView,
        PasswordResetView,
    },
    props: {
        multipleAccounts: Array,
        userData: Object,
        recoveryMethod: String,
        email: String,
        phone: String,
        codeSent: Boolean,
        verificationError: String,
        verificationStatus: String,
        resetError: String
    },
    emits: ['verify-code', 'send-sms-code', 'start-over', 'reset-password', 'start-timer'],
    setup(props, {
        emit
    }) {
        const {
            remainingTime,
            startTimer,
            stopTimer
        } = useTimer(600);
        const isVerifying = ref(false);
        const verifyCode = ({
            email,
            code
        }) => {
            emit('verify-code', email, code);
        };

        const sendVerificationCode = () => {
            emit('send-sms-code');
            startTimer();
        };

        const startOver = () => {
            stopTimer();
            emit('start-over');
        };

        const handleResetPassword = (newPassword) => {
            emit('reset-password', newPassword);
        };

        watch(() => props.resetError, (newVal) => {
            console.log('Reset error in VerificationCodeInput:', newVal);
        });

        watch(() => props.codeSent, (newVal) => {
            if (newVal) {
                console.log('Code sent, starting timer');
                startTimer();
            } else {
                console.log('Code not sent, stopping timer');
                stopTimer();
            }
        }, {
            immediate: true
        });

        return {
            remainingTime,
            verifyCode,
            sendVerificationCode,
            startOver,
            handleResetPassword,
            isVerifying
        };
    },
});
</script>

<style scoped>
.fast-slide-fade-enter-active,
.fast-slide-fade-leave-active {
    transition: all 0.3s ease-out;
}

.fast-slide-fade-enter-from {
    opacity: 0;
    transform: translateX(-30px);
}

.fast-slide-fade-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
