<template>
<div class="w-[800px] h-[600px] bg-gradient-to-br from-gray-100 via-gray-50 to-white shadow-lg relative overflow-hidden">
    <!-- Top abstract shapes -->
    <div class="absolute top-0 left-0 w-[120%] h-20 bg-[#5ccabf] transform -skew-y-3 -translate-y-8"></div>
    <div class="absolute top-0 left-0 w-[120%] h-20 bg-gray-800 transform -skew-y-3 -translate-y-12"></div>

    <!-- Bottom abstract shapes -->
    <div class="absolute bottom-0 left-0 w-[120%] h-24 bg-[#5ccabf] transform skew-y-3 translate-y-12 -translate-x-4"></div>
    <div class="absolute bottom-0 left-0 w-[120%] h-24 bg-gray-800 transform skew-y-3 translate-y-16 -translate-x-4"></div>

    <!-- Content -->
    <div class="h-full flex flex-col justify-between relative z-10 px-10 py-16 -mt-16">
        <!-- Header -->
        <div class="flex justify-between items-center mb-4">
            <div class="text-2xl font-semibold text-gray-800">
                <img src="https://api.refreps.com/images/refreps_logo_web.png" class="w-24 h-24 mt-4" alt="RefReps Logo">
            </div>
            <div class="text-sm text-[#5ccabf]">Cert ID: {{ generateUniqueId() }}</div>
        </div>

        <!-- Main Content -->
        <div class="text-center flex-grow flex flex-col justify-center">
            <div class="text-4xl font-thin text-gray-800 tracking-wider mb-8">
                Certificate of Completion
            </div>
            <div class="text-lg text-gray-600">This is to certify that</div>
            <div class="text-3xl font-bold text-gray-800 mb-4 italic" id="certificateName">{{ name }}</div>
            <div class="text-lg text-gray-600">has successfully completed the course</div>
            <div class="text-2xl font-bold text-gray-800 my-2" id="certificateCourse">{{ course }}</div>
            <div class="text-base text-gray-600 mt-3">Awarded on <span id="certificateDate">{{ dateOfCompletion }}</span></div>
        </div>

        <!-- Signature -->
        <div v-if="isOrgCertificate" class="flex justify-center mt-8">
            <div class="text-center">
                <div class="w-48 border-t-2 border-[#5ccabf] mx-auto my-2"></div>
                <div class="text-lg font-semibold text-gray-800" id="certificateInstructor">{{ instructor }}</div>
                <div class="text-sm text-[#5ccabf]">Instructor</div>
            </div>
        </div>
    </div>
</div>
</template>

    
    
<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },
        course: {
            type: String,
            required: true
        },
        dateOfCompletion: {
            type: String,
            required: true
        },
        instructor: {
            type: String,
            required: true
        },
        isOrgCertificate: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        generateUniqueId() {
            const prefix = 'RR';
            const currentYear = new Date().getFullYear();
            const randomDigits = Math.floor(Math.random() * 10000000000).toString().padStart(7, '0');

            return `${prefix}-${currentYear}-${randomDigits}`;
        }
    }
}
</script>
    
    
<style lang="scss" scoped>
    </style>
