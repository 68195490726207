<template>
<button v-if="!isRegistering" :class="[
      'relative w-full overflow-hidden px-8 py-2 rounded-lg text-white',
      'transition-all duration-300 ease-in-out',
      { 'cursor-not-allowed opacity-80': isClicked, '': !isClicked },
      { 'animate-button-fade': isAnimating },
      'bg-gradient-to-r from-teal-400 to-[#80c9d3]'
    ]" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" @click="handleClick" :disabled="isClicked">
    <div :class="[
        'flex items-center justify-center',
        'transition-transform duration-300 ease-in-out',
        { 'animate-content-animation': isAnimating }
      ]">
        <div class="relative flex items-center transition-all duration-300 ease-in-out" :style="{ transform: `translateX(${isHovered ? '0px' : '0'})` }">
            <font-awesome-icon icon="fa-solid fa-rocket" :class="[
            'mr-3 text-lg transition-all duration-300 ease-in-out',
            { 'rotate-minus-45': isHovered || isAnimating || isClicked }
          ]" />
            <div v-if="isHovered || isAnimating" class="absolute left-[25%] bottom-2.5 -translate-x-1/2">
                <div v-for="particle in particles" :key="particle.id" :class="['particle', { 'sputtering': !acceptedTerms }]" :style="{ 
                    left: `${particle.left}px`,
                    animationDelay: `${particle.delay}ms`,
                    animationDuration: `${particle.duration}ms`
                }"></div>
            </div>
        </div>
        <div class="flex items-center justify-center w-auto">
            <div class="relative h-7 overflow-hidden mx-auto mt-1" :class="{ 'w-[70px]': !isHovered, 'w-[100px]': isHovered && acceptedTerms, 'w-[215px]': isHovered && !acceptedTerms }">
            <transition name="slide-fade">
                <span :key="isHovered" class="absolute top-0 left-0 whitespace-nowrap transition-all duration-300 ease-in-out text-[15px]" :class="{ 'translate-y-full': isHovered }">
                    Register
                </span>
            </transition>
            <transition name="slide-fade">
                <span v-if="isHovered" :key="'hovered'" class="absolute top-0 left-0 whitespace-nowrap transition-all duration-300 ease-in-out -translate-y-full text-[15px]" :class="{ 'translate-y-0': isHovered }">
                    {{ acceptedTerms ? "Let's Do This!" : "Need Permission Before Takeoff" }}
                </span>
            </transition>
        </div>
        </div>
    </div>
</button>
</template>

<script>
export default {
    props: {
        isRegistering: {
            type: Boolean,
            default: false
        },
        acceptedTerms: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            isHovered: false,
            isClicked: false,
            isAnimating: false,
            particles: [],
        };
    },
    methods: {
        handleMouseEnter() {
            if (!this.isAnimating && !this.isClicked) {
                this.isHovered = true;
                this.generateParticles();
            }
        },
        handleMouseLeave() {
            if (!this.isAnimating && !this.isClicked) {
                this.isHovered = false;
            }
        },
        handleClick() {
            this.isClicked = true;
            this.isAnimating = true;
            this.isHovered = true;
            this.generateParticles();

            setTimeout(() => {
                this.isAnimating = false;
                this.isClicked = false;
                this.isHovered = false;
            }, 2000);
        },
        generateParticles() {
            const particleCount = this.acceptedTerms ? 15 : 3;
            const baseDuration = this.acceptedTerms ? 600 : 1000;
            
            this.particles = Array.from({ length: particleCount }, (_, i) => ({
                id: i,
                left: Math.random() * 8 - 4,
                delay: Math.random() * 1000,
                duration: baseDuration + Math.random() * 400
            }));
        },
    },
};
</script>

<style scoped>
@keyframes contentAnimation {
    0% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(5px);
    }

    100% {
        transform: translateY(-200%);
    }
}

.animate-content-animation {
    animation: contentAnimation 1.4s forwards;
}

.rotate-minus-45 {
    transform: rotate(-45deg);
}

.particle {
    position: absolute;
    bottom: -10px;
    width: 4px;
    height: 4px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    animation: particleAnimation 0.6s ease-out infinite;
}

.particle.sputtering {
    animation: sputteringAnimation 3s ease-in-out infinite;
}

@keyframes particleAnimation {
    0% {
        transform: translateY(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateY(10px) scale(0);
        opacity: 0;
    }
}

@keyframes sputteringAnimation {
    0% {
        transform: translateY(0) scale(1);
        opacity: 1;
    }

    25% {
        transform: translateY(2px) translateX(-1px) scale(0.9);
        opacity: 0.8;
    }

    50% {
        transform: translateY(3px) translateX(1px) scale(0.8);
        opacity: 0.6;
    }

    75% {
        transform: translateY(1px) translateX(-0.5px) scale(0.7);
        opacity: 0.4;
    }

    100% {
        transform: translateY(0) translateX(0) scale(0);
        opacity: 0;
    }
}

@keyframes buttonFade {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.animate-button-fade {
    animation: buttonFade 0.6s 1.4s forwards;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(100%);
    opacity: 0;
}
</style>
