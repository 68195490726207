<template>
  <div class="container mx-auto px-4">
    <h2 class="text-2xl font-semibold mb-4 py-2 w-full text-center">Select Your State Association</h2>
    <Map :state="state" @state-click="handleStateClick" @state-hover="handleStateHover" class="mb-8" :suggested-state="suggestedState" />
    <div class="state-info text-center mt-4" style="height: 100px;">
      <template v-if="hoveredState">
        <h2 class="text-2xl font-semibold mb-1 text-gray-700" :style="stateNameStyle">{{ getStateName }}</h2>
        <p class="text-lg text-gray-500" :style="stateAbbreviationStyle">{{ hoveredState }} ({{ getStateInitials }})</p>
      </template>
      <template v-else>
        Suggested State Association:
        <h2 class="text-2xl font-semibold mb-1 text-gray-700" :style="suggestedStateNameStyle">{{ getSuggestedStateName }}</h2>
        <p class="text-lg text-gray-700" :style="suggestedStateAbbreviationStyle">{{ suggestedState }} ({{ getSuggestedStateInitials }})</p>
      </template>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import useMainStore from "@/stores/main";
import Map from "./Map.vue";

export default {
  name: 'StatesMap',
  components: {
    Map
  },
  props: {
    state: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapStores(useMainStore),
    getStateName() {
      if (Array.isArray(this.mainStore.stateAssociations)) {
        const hoveredStateAssociation = this.mainStore.stateAssociations.find(state => state.abbreviation === this.hoveredState);
        return hoveredStateAssociation ? hoveredStateAssociation.name : '';
      }
      return '';
    },
    getStateInitials() {
      if (Array.isArray(this.mainStore.stateAssociations)) {
        const hoveredStateAssociation = this.mainStore.stateAssociations.find(state => state.abbreviation === this.hoveredState);

        return hoveredStateAssociation ? hoveredStateAssociation.initials : '';
      }
      return '';
    },
    getSuggestedStateName() {
      if (Array.isArray(this.mainStore.stateAssociations)) {
        const suggestedStateAssociation = this.mainStore.stateAssociations.find(state => state.abbreviation === this.suggestedState);
        return suggestedStateAssociation ? suggestedStateAssociation.name : '';
      }
      return '';
    },
    getSuggestedStateInitials() {
      if (Array.isArray(this.mainStore.stateAssociations)) {
        const suggestedStateAssociation = this.mainStore.stateAssociations.find(state => state.abbreviation === this.suggestedState);

        return suggestedStateAssociation ? suggestedStateAssociation.abbreviation : '';
      }
      return '';
    },
    stateNameStyle() {
      return { color: '#4A4A4A' };
    },
    stateAbbreviationStyle() {
      return { color: '#6B6B6B' };
    },
    suggestedStateNameStyle() {
      return { color: '#4A4A4A' };
    },
    suggestedStateAbbreviationStyle() {
      return { color: '#4A4A4A' };
    }
  },
  data() {
    return {
      hoveredState: '',
      suggestedState: '',
    }
  },
  methods: {
    handleStateClick(stateAbbreviation) {
      this.hoveredState = stateAbbreviation;
      const selectedState = this.mainStore.stateAssociations.find(state => state.abbreviation === stateAbbreviation);

      this.$emit('state-selected', selectedState ? `${selectedState.abbreviation} (${selectedState.name})` : '');
    },
    handleStateHover(stateAbbreviation) {
      this.hoveredState = stateAbbreviation;
    },
    goBack() {
      this.$emit('go-back-to-overview');
    }
  },
  mounted() {
    this.suggestedState = this.state;
  }
}
</script>

<style scoped>
.state-info {
  transition: all 0.3s ease;
}
.state-name {
  transition: color 0.3s ease;
}
.state-abbreviation {
  transition: color 0.3s ease;
}
</style>