<template>
  <div class="text-center mt-8">
    <h2 class="text-3xl font-bold text-black mb-6">Set Your New Password</h2>
    
        <p class="text-gray-600 mb-6">Please choose a new password. For security reasons, it must be different from your previous password.</p>    

    <div class="mb-6">
      <PasswordInput
        v-model="newPassword"
        label="New Password"
        :show-password="showNewPassword"
        :is-valid="isPasswordValid"
        @toggle-visibility="showNewPassword = !showNewPassword"
      />
    </div>

    <div class="mb-6">
      <PasswordInput
        v-model="confirmPassword"
        label="Confirm Password"
        :is-valid="isConfirmationValid"
        :show-password="showConfirmPassword"
        @toggle-visibility="showConfirmPassword = !showConfirmPassword"
      />
    </div>

    <transition name="fade" mode="out-in">
      <div v-if="resetError" class="mb text-red-700">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" class="mr-2" />
        {{ resetError }}
      </div>
    </transition>

    <div class="mt-8">
      <PasswordRequirements :requirements="passwordRequirements" />
    </div>

    <button
      @click="resetPassword"
      class="w-full mt-8 bg-teal-600 text-white py-3 px-4 rounded-lg hover:bg-teal-700 transition duration-200 font-semibold text-lg"
      :disabled="!canSubmit"
      :class="{ 'opacity-50 cursor-not-allowed': !canSubmit }"
    >
      <font-awesome-icon icon="fa-solid fa-key" class="mr-2" />
      Reset Password
    </button>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import PasswordInput from '@/components/Login/ForgotPassword/PasswordInput.vue';
import PasswordRequirements from '@/components/Login/ForgotPassword/PasswordRequirements.vue';
import { usePasswordValidation } from '@/composables/usePasswordValidation';

const props = defineProps({
  resetError: String
});

const emit = defineEmits(['reset-password']);

const newPassword = ref('');
const showNewPassword = ref(false);
const showConfirmPassword = ref(false);
const passwordResetError = ref('');

const { 
  passwordRequirements, 
  isPasswordValid, 
  isConfirmationValid, 
  confirmPassword 
} = usePasswordValidation(newPassword);

const canSubmit = computed(() => 
  isPasswordValid.value && isConfirmationValid.value
);

const resetPassword = () => {
  if (canSubmit.value) {
    try {
      emit('reset-password', newPassword.value);
    } catch (error) {
      console.error('Error in resetPassword:', error);
      passwordResetError.value = 'An error occurred while resetting the password. Please try again.';
    }
  } else {
    passwordResetError.value = 'Please ensure your passwords match and meet all requirements.';
  }
};

watch(() => props.resetError, (newError) => {
  passwordResetError.value = newError;
});

</script>