<template>
  <div>
    <svg viewBox="0 0 959 593" class="svg-states" @mouseover="onStateHover" @mouseleave="onStateLeave" @click="onStateClick">
      <defs>
        <filter id="dropShadow" x="-20%" y="-20%" width="140%" height="140%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
          <feOffset dx="2" dy="2" result="offsetblur" />
          <feFlood flood-color="#3D3D3D" flood-opacity="0.5" />
          <feComposite in2="offsetblur" operator="in" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <title>U.S. States</title>
      <g id="states">
        <path
          v-for="(state, index) in statesWithStyle"
          :key="index"
          :id="state.id"
          :fill="state.fill"
          :d="state.d"
          :filter="state.filter"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import useMainStore from "@/stores/main";

export default {
  props: {
    state: {
      type: String,
      default: null
    }
  },
  emits: ['state-hover', 'state-leave', 'state-click'],
  computed: {
    ...mapStores(useMainStore),
    statesWithStyle() {
      return this.states.map(state => ({
        ...state,
        fill: this.getStateFill(state.id),
        filter: this.getStateFilter(state.id)
      }));
    }
  },
  data() {
    return {
      selectedState: null,
      hoveredState: null,
      states: [],
      defaultFillColor: '#D3D3D3',
      selectedFillColor: '#00CDC2',
      hoverFillColor: '#8FDCD8',      
      unselectedFillColor: '#E8E8E8',

      animationProgress: 0,
      animationId: null
    }
  },
  methods: {
    onStateHover(event) {
      const stateId = event.target.getAttribute('id');
      this.hoveredState = stateId;
      this.$emit('state-hover', stateId);
      this.resetAnimation();
    },
    onStateLeave(event) {
      this.hoveredState = null;
      this.$emit('state-leave');
      if (this.state) {
        this.startAnimation();
      }
    },
    onStateClick(event) {
      const stateId = event.target.getAttribute('id');
      this.selectedState = this.selectedState === stateId ? null : stateId;
      this.$emit('state-click', this.selectedState);
    },
    getStateFill(stateId) {
      if (stateId === this.selectedState) {
        return this.selectedFillColor;
      }

      if (stateId === this.hoveredState) {
        return this.hoverFillColor;
      }


      if (this.state && stateId === this.state && !this.hoveredState) {
        const interpolatedColor = this.interpolateColor(this.defaultFillColor, this.selectedFillColor, Math.abs(Math.sin(this.animationProgress)));
        return interpolatedColor;
      }
      return this.selectedState ? this.unselectedFillColor : this.defaultFillColor;
    },
    getStateFilter(stateId) {
      return stateId === this.selectedState ? 'url(#dropShadow)' : 'none';
    },
    transformSVGPaths(svgString) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(svgString, 'text/html');
      const pathElements = doc.querySelectorAll('path');
      return Array.from(pathElements).map(pathElement => ({   
        id: pathElement.getAttribute('id'),
        fill: pathElement.getAttribute('fill'),
        d: pathElement.getAttribute('d')
      }));
    },
    interpolateColor(color1, color2, factor) {
      const r1 = parseInt(color1.slice(1, 3), 16);
      const g1 = parseInt(color1.slice(3, 5), 16);
      const b1 = parseInt(color1.slice(5, 7), 16);
      const r2 = parseInt(color2.slice(1, 3), 16);
      const g2 = parseInt(color2.slice(3, 5), 16);
      const b2 = parseInt(color2.slice(5, 7), 16);
      const r = Math.round(r1 + factor * (r2 - r1));
      const g = Math.round(g1 + factor * (g2 - g1));
      const b = Math.round(b1 + factor * (b2 - b1));
      return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    },



    startAnimation() {
      this.resetAnimation();
      const animate = () => {



        this.animationProgress += 0.035;
        this.$forceUpdate();



        this.animationId = requestAnimationFrame(animate);
      };

      this.animationId = requestAnimationFrame(animate);
    },
    resetAnimation() {
      if (this.animationId) {
        cancelAnimationFrame(this.animationId);
        this.animationId = null;
      }
      this.animationProgress = 0;
    }
  },
  async mounted() {
    this.states = this.transformSVGPaths(this.mainStore.svgString);

    if (this.state) {

      this.startAnimation();
    }
  },
  watch: {
    state(newState) {
      if (newState) {


        this.startAnimation();
      } else {
        this.resetAnimation();
      }
    }
  },
  beforeUnmount() {
    this.resetAnimation();
  }
}
</script>

<style lang="scss" scoped>
.svg-states {
  width: 100%;
  height: auto;
}

path {
  transition: fill 0.3s ease, filter 0.3s ease;
  cursor: pointer;
}
</style>