<template>
  <div class="w-full relative flex h-screen"
    style="background-image: url('data:image/svg+xml,%3Csvg width=\'20\' height=\'20\' viewBox=\'0 0 20 20\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M1 1h2v2H1V1zm4 0h2v2H5V1zm4 0h2v2H9V1zm4 0h2v2h-2V1zm4 0h2v2h-2V1zm-16 4h2v2H1V5zm4 0h2v2H5V5zm4 0h2v2H9V5zm4 0h2v2h-2V5zm4 0h2v2h-2V5zm-16 4h2v2H1V9zm4 0h2v2H5V9zm4 0h2v2H9V9zm4 0h2v2h-2V9zm4 0h2v2h-2V9zm-16 4h2v2H1v-2zm4 0h2v2H5v-2zm4 0h2v2H9v-2zm4 0h2v2h-2v-2zm4 0h2v2h-2v-2z\' fill=\'%239C92AC\' fill-opacity=\'0.05\' fill-rule=\'evenodd\'/%3E%3C/svg%3E'); box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.3), 0 0 20px 0px rgba(0, 200, 200, 0.2);">

    <div class="absolute w-full h-full flex items-center justify-center p-8 overflow-y-auto">
      <div class="max-w-lg w-full">
        <button class="mb-8" @click="returnToLogin">
          <i class="fas fa-arrow-left text-2xl"></i>
        </button>

        <template v-if="!showVerificationCode && !showMultipleAccounts">
          <div class="text-center mb-8">
            <h1 class="text-3xl font-bold mb-2">Recover Your Password</h1>
            <p class="text-gray-600">
              You can recover your password using the email address or
              SMS number associated with your account.
            </p>
          </div>

          <form @submit.prevent="handleSubmit" class="space-y-6">
            <fieldset>
              <legend class="text-sm font-semibold leading-6 text-gray-900">Select recovery method:</legend>
              <div class="mt-3 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                <!-- Email recovery option -->
                <label
                  :class="['relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none', recoveryMethod === 'email' ? 'border-teal-400 ring-2 ring-teal-400 bg-gradient-to-r from-gray-50 via-gray-50 to-gray-100' : 'border-gray-300']">
                  <input type="radio" name="recoveryMethod" value="email" v-model="recoveryMethod" class="sr-only">
                  <span class="flex flex-1">
                    <span class="flex flex-col">
                      <span class="block text-sm font-medium text-gray-900">
                        <font-awesome-icon icon="fa-regular fa-envelope" class="w-6 h-6 mr-2" />                        Recover using email
                      </span>
                      <span class="mt-1 flex items-center text-sm text-gray-500">Enter your email address</span>
                    </span>
                  </span>
                  <svg :class="['h-5 w-5 text-teal-400', {'invisible': recoveryMethod !== 'email'}]" viewBox="0 0 20 20"
                    fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clip-rule="evenodd" />
                  </svg>
                  <span
                    :class="['pointer-events-none absolute -inset-px rounded-lg', recoveryMethod === 'email' ? 'border border-teal-400' : 'border-2 border-transparent']"
                    aria-hidden="true"></span>
                </label>

                <!-- SMS recovery option -->
                <label
                  :class="['relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none', recoveryMethod === 'sms' ? 'border-teal-400 ring-2 ring-teal-400 bg-gradient-to-r from-gray-50 via-gray-50 to-gray-100' : 'border-gray-300']">
                  <input type="radio" name="recoveryMethod" value="sms" v-model="recoveryMethod" class="sr-only">
                  <span class="flex flex-1">
                    <span class="flex flex-col">
                      <span class="block text-sm font-medium text-gray-900">
                        <font-awesome-icon icon="fa-regular fa-mobile" class="w-6 h-6 mr-2" />                        Recover using mobile number
                      </span>
                      <span class="mt-1 flex items-center text-sm text-gray-500">Enter your mobile number</span>
                    </span>
                  </span>
                  <svg :class="['h-5 w-5 text-teal-400', {'invisible': recoveryMethod !== 'sms'}]" viewBox="0 0 20 20"
                    fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clip-rule="evenodd" />
                  </svg>
                  <span
                    :class="['pointer-events-none absolute -inset-px rounded-lg', recoveryMethod === 'sms' ? 'border border-teal-400' : 'border-2 border-transparent']"
                    aria-hidden="true"></span>
                </label>
              </div>
            </fieldset>

            <!-- Email input field -->
            <div v-if="recoveryMethod === 'email'" class="p-2.5 border border-gray-200 relative transition-all duration-300 ease-in-out rounded-lg" :class="{ 'bg-gray-50/50': activeField === 'email', 'rounded-lg': recoveryMethod !== 'email' }">
              <div class="flex items-center">
                <transition name="slide-fade">
                  <font-awesome-icon v-if="isValidEmail && !emailWarning" icon="circle-check" class="text-teal-500 mr-1 absolute left-2.5 top-1/2 -mt-1" />
                  <font-awesome-icon v-else-if="emailWarning" icon="fa-circle-exclamation" class="text-yellow-500 mr-1 absolute left-2.5 top-1/2 -mt-1" />
                </transition>
                <label for="email" class="block text-xs font-medium text-gray-500 transition-all duration-300 ease-in-out pl-6" :class="{ 'text-teal-600 text-[10px]': activeField === 'email' }">Email address</label>
              </div>
              <input v-model="email" type="email" id="email" placeholder="johndoe@example.com"
                class="block w-full border-0 p-0 text-gray-700 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 bg-transparent font-medium pl-6"
                @focus="handleFocus('email')" @blur="handleBlur('email')" @input="handleInput" autocomplete="off"
                required />
            </div>
              
            <div v-if="recoveryMethod === 'sms'" class="p-2.5 border border-gray-200 relative transition-all duration-300 ease-in-out rounded-lg" :class="{ 'bg-gray-50/50': activeField === 'phone', 'rounded-lg': recoveryMethod !== 'sms' }">
              <div class="flex items-center">
                <transition name="slide-fade">
                  <font-awesome-icon v-if="isValidPhone && !phoneWarning" icon="circle-check" class="text-teal-500 mr-1 absolute left-2.5 top-1/2 -mt-1" />
                  <font-awesome-icon v-else-if="phoneWarning" icon="fa-circle-exclamation" class="text-yellow-500 mr-1 absolute left-2.5 top-1/2 -mt-1" />
                </transition>
                <label for="phone" class="block text-xs font-medium text-gray-500 transition-all duration-300 ease-in-out pl-6" :class="{ 'text-teal-600 text-[10px]': activeField === 'phone' }">Mobile number</label>
              </div>
              <input v-model="phone" type="tel" id="phone" placeholder="(555) 555-5555"
                class="block w-full border-0 p-0 text-gray-700 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 bg-transparent font-medium pl-6"
                @input="phone = formatPhoneNumber($event.target.value)"
                @focus="handleFocus('phone')" @blur="handleBlur('phone')" autocomplete="off"
                required />
            </div>
            <div>
              <button type="submit"
                class="w-full bg-teal-400 text-white py-3 px-4 rounded-lg hover:bg-teal-500 transition duration-200 font-semibold"
                :disabled="isLoading">
                {{ isLoading ? 'Processing...' : 'Send Verification Code' }}
              </button>
            </div>

            <div v-if="message.text"
              :class="['text-center', message.type === 'success' ? 'text-green-600' : 'text-red-600']">
              {{ message.text }}
            </div>

            <div class="text-center">
              <a href="#" @click.prevent="returnToLogin" class="text-black hover:underline font-medium">
                <font-awesome-icon icon="arrow-left" class="mr-1" />
                Return to login
              </a>
            </div>
          </form>
        </template>

       <transition name="slide-fade-fast" mode="out-in">
        <MultipleAccountsView 
          v-if="showMultipleAccounts"
          :accounts="multipleAccounts"
          @select-account="handleSelectAccount"
        />
        
      </transition>

      <transition name="slide-fade-fast" mode="out-in">

        <VerificationCodeInput 
    v-if="showVerificationCode"
    :multipleAccounts="multipleAccounts"
    :userData="userData"
    :recoveryMethod="recoveryMethod"
    :email="email"
    :phone="phone"
    :codeSent="codeSent"
    :verificationError="verificationError"
    :verificationStatus="verificationStatus"
    :resetError="resetError"
    @start-timeout="startTimer"
    @select-account="handleSelectAccount"
    @verify-code="handleVerifyCode"
    @send-sms-code="sendSmsVerification"
    @start-over="closeVerification"
    @reset-password="handleResetPassword"
  />      

      </transition>
          

        <footer class="mt-8 text-center text-sm text-gray-500">
          <p>©2024 All Rights Reserved. RefReps, LLC is a registered trademark.</p>
          <p>
            <a href="https://www.refreps.com/privacy" target="_blank" class="text-teal-600 hover:underline">Privacy</a> ·
            <a href="https://www.refreps.com/terms" target="_blank" class="text-teal-600 hover:underline">Terms</a>          </p>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed, watch } from 'vue'
import axios from 'axios'
import VerificationCodeInput from '@/components/Login/ForgotPassword/VerificationCodeInput.vue'
import MultipleAccountsView from '@/components/Login/ForgotPassword/MultipleAccountsView.vue'

export default {
  name: 'ForgotPassword',
  components: {
    VerificationCodeInput,
    MultipleAccountsView
  },
  setup(props, { emit }) {
    const showVerificationCode = ref(false)
    const showMultipleAccounts = ref(false)
    const recoveryMethod = ref('email')
    const email = ref('')
    const phone = ref('')
    const isLoading = ref(false)
    const message = reactive({
      text: '',
      type: ''
    })
    const resetError = ref('')
    const userData = ref({})
    const multipleAccounts = ref([])
    const activeField = ref(null)
    const emailWarning = ref(false)
    const phoneWarning = ref(false)
    const isCodeVerified = ref(false)

    const resetToken = ref(null)
    const verificationStatus = ref('')
    const verificationError = ref('')
    const isVerifying = ref(false)
    const codeSent = ref(false)
    const newPassword = ref('')

    const isValidEmail = computed(() => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(email.value)
    })

    const isValidPhone = computed(() => {
      const phoneRegex = /^\(\d{3}\)\s\d{3}-\d{4}$/
      return phoneRegex.test(phone.value)
    })

    watch([showVerificationCode, codeSent, multipleAccounts], () => {
      console.log('State changed:', { 
        showVerificationCode: showVerificationCode.value, 
        codeSent: codeSent.value, 
        hasMultipleAccounts: multipleAccounts.value !== null 
      });
    });

    const handleInput = () => {
      if (recoveryMethod.value === 'email') {
        emailWarning.value = !isValidEmail.value && email.value.length > 0
      } else if (recoveryMethod.value === 'sms') {
        phoneWarning.value = !isValidPhone.value && phone.value.length > 0
      }
    }

    const handleFocus = (field) => {
      activeField.value = field
    };
    
    const handleBlur = (field) => {
      activeField.value = null
      if (field === 'email' && email.value.trim() === '') {
        email.value = ''
      }
      if (field === 'phone' && phone.value.trim() === '') {
        phone.value = ''
      }
    }

    const formatPhoneNumber = (input) => {
      const cleaned = ('' + input).replace(/\D/g, '').slice(0, 10)
      const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/)
      if (match) {
        return '(' + match[1] + (match[2] ? ') ' : '') + match[2] + (match[3] ? '-' + match[3] : '')
      }
      return input
    }

    const handleSubmit = async () => {
      try {
        if (recoveryMethod.value === 'email') {
          multipleAccounts.value = null;
          await sendPasswordResetEmail();
        } else if (recoveryMethod.value === 'sms') {
          await checkForMultipleAccounts();
        }
      } catch (error) {
        console.error('Error in handleSubmit:', error);
        message.text = 'An unexpected error occurred. Please try again.';
        message.type = 'error';
      }
    };

    const handleResetPassword = async (password) => {

  if (!resetToken.value) {
    console.log('Reset token is missing. Please request a new password reset.');
    resetError.value = 'Reset token is missing. Please request a new password reset.';
    return;
  }
  
  try {
    const response = await axios.post(`${import.meta.env.VITE_BASE_LINK}/reset-password`, {
      email: email.value,
      password: password,
      token: resetToken.value
    });

    if (response.data.code === 200) {
      resetError.value = '';
      newPassword.value = password;
      returnToLoginFromReset();
    } else {
      throw new Error(response.data.message || 'An error occurred while resetting the password.');
    }
  } catch (error) {
    console.error('Error resetting password:', error);
    resetError.value = error.response?.data?.message || error.message || 'An error occurred while resetting the password. Please try again.';
  }
};


const handleVerifyCode = async (email, code) => {
  isLoading.value = true
  verificationError.value = ''
  verificationStatus.value = ''
  
  try {
    const response = await axios.post(`${import.meta.env.VITE_BASE_LINK}/verify-sms-code`, {
      email: email,
      smsCode: code
    })
    console.log('Reset Token:', response.data.resetToken);  // Changed to log 'resetToken'
    if (response.data.isVerified) {
      verificationStatus.value = 'success'
      resetToken.value = response.data.resetToken  // Changed to 'resetToken'
      console.log('Token set:', resetToken.value);  // Added to confirm token is set
    } else {
      throw new Error(response.data.error || 'Verification failed')
    }
  } catch (error) {
    console.error('Verification error:', error)
    verificationStatus.value = 'error'
    verificationError.value = error.response?.data?.message || error.message || 'Invalid verification code. Please try again.'
  } finally {
    isLoading.value = false
  }
}

    const returnToLogin = () => {
      emit('back-to-home')
    }

    const returnToLoginFromReset = () => {
      userData.value = { ...userData.value, password: newPassword.value };
      emit('back-to-home-from-reset', userData.value);
    }

    const sendPasswordResetEmail = async () => {
  isLoading.value = true;
  message.text = '';
  message.type = '';

  try {
    const response = await axios.post(`${import.meta.env.VITE_BASE_LINK}/forgot-password`, {
      email: email.value
    });
    message.text = response.data.message;
    message.type = 'success';
    userData.value = response.data.user;
    codeSent.value = true; // Ensure this is set to true
    showVerificationCode.value = true;
  } catch (error) {
    message.text = error.response?.data?.message || 'An error occurred. Please try again.';
    message.type = 'error';
  } finally {
    isLoading.value = false;
  }
};

    const handleSelectAccount = async (selectedUserData) => {
      isLoading.value = true;
      message.text = '';
      message.type = '';

      try {
        const response = await axios.post(
          `${import.meta.env.VITE_BASE_LINK}/send-sms-verification`,
          {
            email: selectedUserData.email,
            phone: selectedUserData.phone
          }
        );

        if (response.data.code == 200) {
          email.value = selectedUserData.email;
          phone.value = selectedUserData.phone;
          userData.value = selectedUserData;
          multipleAccounts.value = null;
          showMultipleAccounts.value = false;
          showVerificationCode.value = true;
          codeSent.value = true;
          console.log('Account selected and SMS sent:', { email: email.value, phone: phone.value, codeSent: codeSent.value });
        } else {
          throw new Error(response.data.message || 'Failed to send verification code');
        }
      } catch (error) {
        message.text = error.response?.data?.message || error.message || 'An error occurred. Please try again.';
        message.type = 'error';
      } finally {
        isLoading.value = false;
      }
    };

    const sendSmsVerification = async () => {
      isLoading.value = true;
      message.text = '';
      message.type = '';
   
      if (!email.value && !phone.value) {
        message.text = 'Please enter either an email or phone number.';
        message.type = 'error';
        isLoading.value = false;
        return;
      }

      try {
        const response = await axios.post(
          `${import.meta.env.VITE_BASE_LINK}/send-sms-verification`,
          {
            email: email.value,
            phone: phone.value
          }
        );
        message.text = response.data.message;
        message.type = 'success';
        userData.value = response.data.user;
        showVerificationCode.value = true;
        codeSent.value = true;
      } catch (error) {
        message.text = error.response?.data?.message || 'An error occurred. Please try again.';
        message.type = 'error';
      } finally {
        isLoading.value = false;
      }
    };

    const checkForMultipleAccounts = async () => {
      isLoading.value = true;
      message.text = '';
      message.type = '';

      try {
        const response = await axios.post(`${import.meta.env.VITE_BASE_LINK}/check-for-multiple-accounts`, {
          phone: phone.value
        });
        
        if (response.data.multipleAccounts) {
          multipleAccounts.value = response.data.users;
          showMultipleAccounts.value = true;
          showVerificationCode.value = false;
        } else {
          userData.value = response.data.user;
          email.value = response.data.user.email;
          phone.value = response.data.user.phone;
          await sendSmsVerification();
        }
      } catch (error) {
        message.text = error.response?.data?.message || 'An error occurred. Please try again.';
        message.type = 'error';
      } finally {
        isLoading.value = false;
      }
    };



    const closeVerification = () => {
      showVerificationCode.value = false
      message.text = ''
      message.type = ''
      recoveryMethod.value = 'email'
      email.value = ''
      phone.value = ''
      userData.value = {}
    }


    onMounted(() => {
      recoveryMethod.value = 'email'
      email.value = ''
      phone.value = ''
      message.text = ''
      message.type = ''
      userData.value = {}
      showVerificationCode.value = false
      isLoading.value = false
    })

    return {
      showVerificationCode,
      recoveryMethod,
      email,
      phone,
      isLoading,
      message,
      handleSubmit,
      returnToLogin,
      userData,
      sendSmsVerification,
      closeVerification,
      formatPhoneNumber,
      multipleAccounts,
      handleBlur,
      handleSelectAccount,
      handleFocus,
      activeField,
      emailWarning,
      phoneWarning,
      isValidEmail,
      isValidPhone,
      handleInput,
      checkForMultipleAccounts,
      handleVerifyCode,
      isCodeVerified,
      handleResetPassword,
      verificationError,
      verificationStatus,
      resetToken,
      isVerifying,
      codeSent,
      showMultipleAccounts,
      resetError
    }
  }
}
</script>

<style>

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

</style>
