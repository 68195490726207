<template>
<div class="w-full">
    <label :for="id" class="block text-lg font-bold mb-4 text-gray-800 text-left">{{ label }}</label>
    <div class="relative" ref="dropdownRef">
        <button :id="id" type="button" :class="`inline-flex justify-between items-center w-full px-4 py-3 text-lg font-light text-gray-700 bg-white hover:bg-teal-100/70 focus:outline-none focus:ring-2 focus:ring-teal-500 transition-all duration-300 ${isOpen ? 'shadow-lg' : 'shadow-sm'} relative overflow-hidden hover:shadow-2xl hover:shadow-teal-200`" @click="toggleDropdown" style="background-image: url('data:image/svg+xml,%3Csvg width=\'20\' height=\'20\' viewBox=\'0 0 20 20\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M1 1h2v2H1V1zm4 0h2v2H5V1zm4 0h2v2H9V1zm4 0h2v2h-2V1zm4 0h2v2h-2V1zm-16 4h2v2H1V5zm4 0h2v2H5V5zm4 0h2v2H9V5zm4 0h2v2h-2V5zm4 0h2v2h-2V5zm-16 4h2v2H1V9zm4 0h2v2H5V9zm4 0h2v2H9V9zm4 0h2v2h-2V9zm4 0h2v2h-2V9zm-16 4h2v2H1v-2zm4 0h2v2H5v-2zm4 0h2v2H9v-2zm4 0h2v2h-2v-2zm4 0h2v2h-2v-2z\' fill=\'%239C92AC\' fill-opacity=\'0.05\' fill-rule=\'evenodd\'/%3E%3C/svg%3E'); box-shadow: 0 5px 15px -3px rgba(0, 0, 0, 0.1), 0 0 20px 0px rgba(0, 200, 200, 0.1); transition: all 0.3s ease-in-out;">
            <span>{{ selectedItem ? selectedItem.label : placeholder }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" :class="`w-5 h-5 ml-2 text-teal-500 transition-transform duration-300 ${isOpen ? 'transform rotate-180' : ''}`" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" /></svg>
            <span :class="`absolute bottom-0 left-1/2 w-0 h-0.5 bg-teal-500 transition-all duration-300`" :style="{ transform: isOpen ? 'translateX(-50%) scaleX(1)' : 'translateX(-50%) scaleX(0)', width: isOpen ? '100%' : '0%' }"></span>
        </button>
        <div v-if="isOpen" class="absolute z-10 w-full mt-1 bg-white shadow-xl transition-all duration-300 ease-in-out transform opacity-100 scale-100 origin-center" style="max-height: 200px; overflow-y: auto;">
            <div class="py-1" role="menu" aria-orientation="vertical" :aria-labelledby="id">
                <a v-for="item in items" :key="item.value" href="#" class="flex items-center justify-between px-4 py-2 text-base text-gray-700 hover:bg-teal-50 transition-colors duration-200" role="menuitem" @click="handleItemClick(item)">
                    <span class="font-light">{{ item.label }}</span>
                    <svg v-if="selectedItem && selectedItem.value === item.value" xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-teal-500" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" /></svg>
                </a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ref,
    computed,
    onMounted,
    onUnmounted
} from 'vue'

export default {
    name: 'CleanCreativeDropdown',
    props: {
        id: {
            type: String,
            default: 'dropdown'
        },
        label: {
            type: String,
            default: 'Choose Your Path'
        },
        items: {
            type: Array,
            required: true,
            validator: (value) => value.every(item => 'value' in item && 'label' in item)
        },
        placeholder: {
            type: String,
            default: 'Select an option'
        },
        selectionMessageTemplate: {
            type: String,
            default: "You've chosen the path of {selection}."
        }
    },
    emits: ['update:modelValue', 'click-outside'],
    setup(props, { emit }) {
        const isOpen = ref(false)
        const selectedItem = ref(null)
        const dropdownRef = ref(null)

        const toggleDropdown = () => {
            isOpen.value = !isOpen.value
        }

        const handleItemClick = (item) => {
            selectedItem.value = item
            isOpen.value = false
            emit('update:modelValue', item.value)
        }

        const selectionMessage = computed(() => {
            if (selectedItem.value) {
                return props.selectionMessageTemplate.replace('{selection}', selectedItem.value.label.toLowerCase())
            }
            return ''
        })

        const handleClickOutside = (event) => {
            if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
                isOpen.value = false
                emit('click-outside')
            }
        }

        onMounted(() => {
            document.addEventListener('click', handleClickOutside)
        })

        onUnmounted(() => {
            document.removeEventListener('click', handleClickOutside)
        })

        return {
            isOpen,
            selectedItem,
            toggleDropdown,
            handleItemClick,
            selectionMessage,
            dropdownRef
        }
    }
}
</script>
