// File: src/utils/dateUtils.js

function formatTimestamp(timestamp) {
  if (!timestamp) {
    return 'Never logged in';
  }
  const date = new Date(timestamp * 1000);
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const suffix = ['th', 'st', 'nd', 'rd'][(day % 10 > 3) ? 0 : (day % 100 - day % 10 != 10) * (day % 10)];

  return `${month} ${day}${suffix}, ${year}`;
}

// Change this export
export { formatTimestamp };
